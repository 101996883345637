<template>
  <v-container fill-height fluid>
    <div id="login-lang-switch">
      <v-tooltip left :open-delay="300">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="mr-2">
            <label class="avoore-switch lang-switch">
              <input
                type="checkbox"
                :checked="localeIsEnglish"
                @change="changeLang"
              />
              <span data-on="EN" data-off="ID"></span>
            </label>
          </div>
        </template>
        <span class="d-sm-none d-md-block">{{
          $t("tooltip.lang-switch")
        }}</span>
      </v-tooltip>
    </div>
    <v-row align="center" justify="center">
      <v-col cols="12 d-flex justify-center align-center">
        <v-card
          class="d-flex justify-center auth__container align-center"
          height="100"
          outlined
          tile
        >
          <v-card outlined tile class="elemen__logo mb-4">
            <img
              class="mb-2"
              src="@/assets/img/login-logo.png"
              alt="ELEMEN4"
              height="120px"
            />
            <div style="text-align: center" class="dark-text">
              <h2>ARSIP {{ appTitle }}<br>Layanan Arsip Memo Dan Surat</h2>
              <h1 v-if="isTesting" class="error--text">{{ $t('label.testing-purpose-only') }}</h1>
              <p>{{ $t("text.select-action-button-below") }}</p>
            </div>
          </v-card>
        </v-card>
      </v-col>
      <v-row class="mt-6 max__width-380 pa-1">
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 size__75px mt-4 mr-2 btn__opaque"
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                x-large
                v-on:click="showHideLogin(true)"
              >
                <v-icon>{{ iconLogin }}</v-icon></v-btn
              >
            </template>
            <span>{{ $t("tooltip.login") }}</span>
          </v-tooltip>
          <p class="mt-2 text-center login__label">
            {{ $t("label.login") }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mr-2 size__75px mt-4 mx-2 btn__opaque"
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                x-large
                v-on:click="showHideForgot(true)"
              >
                <v-icon>{{ iconForgot }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("tooltip.forgot-password") }}</span>
          </v-tooltip>
          <p class="mt-2 text-center login__label">
            {{ $t("label.forgot-password") }}
          </p>
        </v-col>
        <v-col cols="4">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="size__75px mt-4 mx-2 btn__opaque"
                color="primary"
                outlined
                v-on="on"
                v-bind="attrs"
                x-large
                v-on:click="showHideActivation(true)"
              >
                <v-icon>{{ iconActivation }}</v-icon></v-btn
              >
            </template>
            <span>{{ $t("tooltip.activation") }}</span>
          </v-tooltip>
          <p class="mt-2 text-center login__label">
            {{ $t("label.activation") }}
          </p>
        </v-col>
      </v-row>
      <v-col cols="12" class="d-flex justify-center align-center">
        <img
          src="@/assets/img/footer_logo_elemen4.png"
          alt="logo-footer"
          width="220px"
        />
      </v-col>
      <v-col cols="12" class="d-block text-center" style="margin-top: -1rem;">
        <p style="font-size: 0.8rem; margin-bottom: -1rem;margin-left: 1.8rem;font-weight: 600;">Powered By:</p>
        <img
          src="@/assets/img/bsre.png"
          alt="logo-footer"
          width="120px"
        />
      </v-col>
    </v-row>

    <activation
    ></activation>
    <login
      v-bind:loginShow="loginShow"
      v-on:showHideLogin="showHideLogin"
      v-on:subscribePushNotification="subscribePushNotification"
    ></login>
    <forgot-password
    ></forgot-password>
    <version-select-modal v-bind:versionSelectActive="vSelectActive" v-on:closeVersionSelect="vSelectActive=false"></version-select-modal>
  </v-container>
</template>
<script>
import Activation from "./Activation";
import Login from "./Login";
import ForgotPassword from "./ForgotPassword";
import VersionSelectModal from "../../components/commonComponents/VersionSelectModal.vue";
import { mdiAccountReactivate, mdiLockQuestion, mdiLogin } from "@mdi/js";
import {
  appName,
  shortCompanyName,
  appVersion,
  vapidPublicKey,
  testingPurpose,
} from "@/constants/config";
import { mapState, mapActions } from "vuex";

export default {
  name: "auth-index",
  components: {
    Activation,
    Login,
    ForgotPassword,
    VersionSelectModal,
  },
  data: () => ({
    iconLogin: mdiLogin,
    iconForgot: mdiLockQuestion,
    iconActivation: mdiAccountReactivate,
    loginShow: false,
    vSelectActive: false
  }),
  computed: {
    ...mapState(["currentLanguage"]),
    ...mapState("user", ["tokenCountDown"]),
    appTitle() {
      return appName;
    },
    appLongName(){
      return shortCompanyName;
    },
    versionTitle() {
      return this.$t("label.version") + " " + appVersion;
    },
    localeIsEnglish() {
      return this.currentLanguage == "en";
    },
    isTesting(){
      return testingPurpose;
    }
  },
  mounted() {
    switch (this.$route.params.action) {
      case "login":
        this.showHideLogin(true);
        break;
      case "forgotpass":
        this.showHideForgot(true);
        break;
      case "activation":
        this.showHideActivation(true);
        break;
      default:
        break;
    }
    if (this.tokenCountDown && this.tokenCountDown > 1)
      this.setCountDownTime(this.tokenCountDown - 1);
      this.vSelectActive = true;
  },
  methods: {
    ...mapActions(["setLang"]),
    ...mapActions("user", ["loginAsCancel", "setCountDownTime","subscribeNotif","setforgotShow","setActivationShow"]),
    changeLang(e) {
      if (e.target.checked) {
        this.setLang("en");
      } else {
        this.setLang("id");
      }
    },
    showHideLogin(val) {
      setTimeout(() => {
        // this.loginAsCancel();
        this.loginShow = val;
      }, 300);
    },
    showHideForgot(val) {
      setTimeout(() => {
        this.setforgotShow(val);
      }, 300);
    },
    showHideActivation(val) {
      setTimeout(() => {
       this.setActivationShow(val);
      }, 300);
    },
    subscribePushNotification() {
      if (window.location.hostname === 'localhost') return; //no subscription if localhost
      let that = this;
      if (!navigator.serviceWorker) return;
      navigator.serviceWorker.ready.then(function (serviceWorkerRegistration) {
        let uint8Bit = that.urlBase64ToUint8Array(vapidPublicKey);
        let options = {
          userVisibleOnly: true,
          applicationServerKey: uint8Bit,
        };

        serviceWorkerRegistration.pushManager
          .subscribe(options)
          .then((subscription) => {

            console.info('Starting push subscription');

            const key = subscription.getKey("p256dh");
            const token = subscription.getKey("auth");
            const contentEncoding = (PushManager.supportedContentEncodings || [
              "aesgcm",
            ])[0];
            
            const params = {
              endpoint: subscription.endpoint,
              publicKey: key
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(key)))
                : null,
              authToken: token
                ? btoa(String.fromCharCode.apply(null, new Uint8Array(token)))
                : null,
              contentEncoding,
            };
            //call backend subscription from vuex user
            that.subscribeNotif(params);
          })
          .catch(function (e) {
            if (Notification.permission === "denied") {
              console.warn("Notification permission is not granted by the user");
              that.isPushEnabled = false;
            } else {
              console.error("Push subscription is fail", e);
              that.isPushEnabled = false;
            }
          });
      });
    },
  },
};
</script>
<style>
.auth__container {
  background-color: transparent !important;
  border: none !important;
}
.elemen__logo {
  text-align: center !important;
  border: none !important;
  background-color: transparent !important;
}
.btn__opaque {
  background-color: #eee;
}
.size__75px {
  height: 75px !important;
  width: 75px !important;
}
.size__75px .v-icon__svg,
.size__75px .v-icon {
  height: 50px !important;
  width: 50px !important;
}
.max__width-380 {
  max-width: 380px !important;
}
.login__label {
  color: #7a898c !important;
}
#login-lang-switch {
  position: absolute !important;
  top: 1rem !important;
  right: 1rem !important;
  max-width: 50px !important;
}
@media only screen and (min-width: 1024px) {
  #login-lang-switch {
    right: 40% !important;
  }
}
.v-messages__message {
  line-height: 15px !important;
}
.root__logo .v-image__image {
  background-size: auto !important;
}
.dark-text, .dark-text h1, .dark-text h2{
  color: #eee !important;
}
</style>