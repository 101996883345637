<script>
export default {
    props: {
        versionSelectActive: Boolean,
    },
    data: () => ({}),
    methods: {
        closeMe() {
            this.$emit("closeVersionSelect", false);
        },
        gotoNewElemen(){
            window.open("https://elemen.ptpn.id","_self")
        }
    }
}
</script>
<template>
    <v-row justify="center">
        <v-dialog v-model="versionSelectActive" persistent max-width="400">
            <v-card>
                <v-card-title class="text-h5">
                    {{ $t("card.select version") }}
                </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="6" class="d-flex justify-center">
                            <v-card color="#e7680b" elevation="0" style="width: 100%;cursor: pointer;" class="version-card" @click="closeMe">
                                <v-card-title class="text-h5 text-white" style="text-align: center;">
                                    <div style="display:block;width: 100%;margin: 0 auto;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-archive"><rect width="20" height="5" x="2" y="3" rx="1"/><path d="M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8"/><path d="M10 12h4"/></svg>
                                    </div>
                                    <div style="text-align: center;width: 100%;">
                                        Versi Lama
                                    </div>
                                </v-card-title>
                                <v-card-subtitle class="text-base text-white" style="text-align: center;width: 100%;">Akses Arsip</v-card-subtitle>
                            </v-card>
                        </v-col>
                        <v-col cols="6" class="d-flex justify-center">
                            <v-card color="#13afa6" elevation="0" style="width: 100%;cursor: pointer;" class="version-card"  @click="gotoNewElemen">
                                <v-card-title class="text-h5 text-white" style="text-align: center;">
                                    <div style="display:block;width: 100%;margin: 0 auto;">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-mail-check"><path d="M22 13V6a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2v12c0 1.1.9 2 2 2h8"/><path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"/><path d="m16 19 2 2 4-4"/></svg>
                                    </div>
                                    <div style="text-align: center;width: 100%;">
                                        Versi Baru
                                    </div>
                                </v-card-title>
                                <v-card-subtitle class="text-base text-white" style="text-align: center;width: 100%;">Operasional</v-card-subtitle>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<style lang="css">
.text-base {
    font-size: 1.03rem;
}
.card-subtitle.text-white, .text-white {
    color: #fff !important;
}
.version-card {
    transition: transform .3s;
}
.version-card:hover {
    transform: scale(1.05);
}
</style>
